.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
